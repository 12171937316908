import React, { FC, useState } from 'react';
import { ExclamationCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import create from 'zustand';

// components
import Modal from 'components/Modal';
import Button from 'components/Button';
import Stack from 'components/Stack';
import clsx from 'clsx';

const variants: any = {
  error: 'text-hazel-red-100',
  success: 'text-green-500',
  warning: 'text-yellow-500'
};

const ConfirmDialog: FC = () => {
  const { title, message, onSubmit, close, open, variant = 'error' } = useConfirmDialogStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await onSubmit(true);
      setIsLoading(false);
      close();
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      title={title}
      classNameTitle="bg-hazel-white-200 text-black"
      round="2xl"
      isConfirmOpen
    >
      <div className="space-y-8">
        <div className="flex justify-center">
          {variant === 'warning' ? (
            <ExclamationIcon className={clsx('h-24 w-24', variants[variant])} />
          ) : (
            <ExclamationCircleIcon className={clsx('h-24 w-24', variants[variant])} />
          )}
        </div>
        <div className="text-center">{message}</div>

        <Stack spacingX={3} className="justify-center">
          <Button
            variant="invisible"
            rounded="full"
            classNameButton="px-12 py-2"
            disabled={isLoading}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            variant="hazel-btn-red-100"
            rounded="full"
            classNameButton="px-12 py-2"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => handleDelete()}
          >
            Confirm
          </Button>
        </Stack>
      </div>
    </Modal>
  );
};

interface IConfirm {
  title: string;
  message: string;
  variant?: 'error' | 'success' | 'warning';
  onSubmit: (x: boolean) => Promise<any> | void;
}

const useConfirmDialogStore = create<IConfirm & { close: () => void; open: boolean }>((set) => ({
  title: '',
  message: '',
  open: false,
  variant: 'error',
  onSubmit: (open: boolean) => set({ open }),
  close: () => set({ open: false })
}));

export const confirmDialog = ({ title, message, variant = 'error', onSubmit }: IConfirm) => {
  useConfirmDialogStore.setState({
    title,
    open: true,
    variant,
    message,
    onSubmit
  });
};

export default ConfirmDialog;
