export const patientObject = `
  patient {
    id
    dispensing_interval
    email
    timezone
    contact_no
    secondary_contact_no
    profile {
      first_name
      middle_name
      last_name
      pronoun
      height
      weight
      gender
      current_gender
      date_of_birth
      street
      city
      state
      postcode
      country
      postal_street
      postal_city
      postal_state
      postal_postcode
      postal_country
      medicare_no
      profile_pic
      careteam_notes
      careteam_notes_last_edit_by
      careteam_notes_last_edit_at
      patient_notes
      communication_preference
    }
    background {
      approach_your_health
      diagnosed_health_condition
      diagnosed_health_condition_others
      experiencing_any_pain
      experiencing_any_pain_other
      gastro
      health_goals
      health_goals_others
      hear_about
      interested_speciality
      menopausal
      menstruation
      mood
      other
      other_symptoms
      pain
      sexual_health
      undiagnosed_health_condition
      vaginal_health
      virtual_not_appropriate_reason
      what_brings_you_to_hazel
      whatelse_interested
      whatelse_interested_others
    }
    health_history {
      has_diagnosed_reproductive_condition
      diagnosed_conditions
      symptoms
      any_other_condition_aware_of_derma
      any_other_condition_aware_of_mental
      any_other_condition_aware_of_cardio
      any_other_condition_aware_of_muskolo
      any_other_condition_aware_of_gastro
      any_other_condition_aware_of_urinary
      any_other_condition_aware_of_metabolic
      any_other_condition_aware_of_auto_immunity
      any_other_condition_aware_of_nuero
      any_other_condition_aware_of_reproductive
      impact_daily_life_personal
      impact_daily_life_mental
      impact_daily_life_financial
      impact_daily_life_physical
      seeing_any_professional
      seen_practitioner_others
      investigation_surgical_intervention
      investigation_surgical_intervention
      investigation_surgical_intervention_other
      investigation_surgical_surgeries
      natural_therapies_alternatives
      taking_any_medication
      have_allergies
      last_pap_smear
      do_you_smoke
      do_you_drink
      mens_cycle_frequency
      mens_period_last
      mens_flow_volume
      mens_blood_color
      mens_apperance
      mens_pain_level
      other_notes
      status
      surgeries_others
      practitioner_contact_details
      medication_list
    }
    billing_address {
      street
      city
      state
      postcode
    }
    delivery_address {
      street
      city
      state
      postcode
    }
    phone_verification
    email_verification
    id_verification
    status
    consent_approved
    shop_consent_approved
    same_delivery
    same_billing
    wallet_balance
    checkout_enabled
    onboarding_step
    pinned_alert
  }
`;

export const doctorObject = `
  doctor {
    id
    email
    timezone
    contact_no
    doctor_type_id
    doctor_type{
      id
      description
      has_lifestyle
      has_mental
      has_nutrition
      has_prescriptions
      has_referrals
      has_sexual
      has_supplements
      has_naturopathy
    }
    profile {
      doctor_id
      first_name
      last_name
      date_of_birth
      street
      city
      state
      postcode
      country
      profile_pic
      description
      provider_no
      prescriber_no
      registration_no
      onboarding_step
    }
    phone_verification
    email_verification
    status
  }
`;
