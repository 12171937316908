import React, { FC, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { BookmarkIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { IProductDetails } from 'utils/models';
import { format } from 'date-fns';
import storage from 'utils/storage';

// gql
import { useLazyQuery } from '@apollo/client';
import query from 'graphql/query';

// components
import { SvgCapsule, SvgFlower, SvgOil, SvgStar } from 'components/SvgIcons';
import EScriptProductImage from './EScriptProductImage';

interface IProductDetailId {
  itemId: string;
  bookmarkedProducts: string[];
  setBookmarkedProducts: (products: string[]) => void;
}

const ProductDetails: FC<IProductDetailId> = ({
  itemId,
  bookmarkedProducts,
  setBookmarkedProducts
}) => {
  const [getProductDetails, { data, loading }] = useLazyQuery<{
    productDetails: {
      code: string;
      success: boolean;
      product: IProductDetails;
      message: string;
    };
  }>(query.productDetails, { fetchPolicy: 'network-only' });
  const product = data?.productDetails?.product;

  useEffect(() => {
    getProductDetails({
      variables: {
        id: itemId
      }
    });
  }, [getProductDetails, itemId]);

  const handleSaveBookmarkedIds = () => {
    const currentIds = [...bookmarkedProducts];

    if (!currentIds.includes(itemId)) {
      currentIds.push(itemId);
      setBookmarkedProducts(currentIds);
      storage.setItem('bookmarked-ids', JSON.stringify(currentIds));
    } else {
      const filteredItems = currentIds.filter((item) => item !== itemId);
      setBookmarkedProducts(filteredItems);
      storage.setItem('bookmarked-ids', JSON.stringify(filteredItems));
    }
  };

  if (loading) return <>Loading...</>;

  return (
    <Fragment>
      <div className="bg-hazel-solid-green-600 flex justify-between rounded-t-xl">
        <div className="flex items-center py-4">
          <span>
            {product?.product_type?.icon === 'FLOWER' && (
              <SvgFlower className="w-10 h-10 mx-6 text-hazel-solid-green-600" />
            )}
            {product?.product_type?.icon === 'OIL' && (
              <SvgOil className="w-10 h-10 mx-6 text-hazel-solid-green-600" />
            )}
            {product?.product_type?.icon === 'ORAL' && (
              <SvgCapsule className="w-10 h-10 mx-6 text-hazel-solid-green-600" />
            )}
          </span>
          <div>
            <p className="text-white">Product Information Sheet</p>
            <h2 className="text-white text-xl font-bold">{product?.product_name}</h2>
          </div>
        </div>
        <div className="flex items-center justify-end py-4 mx-6">
          <button type="button" onClick={handleSaveBookmarkedIds}>
            <BookmarkIcon
              className={clsx(
                `w-10 h-10`,
                bookmarkedProducts.includes(itemId) ? 'text-hazel-orange-100' : `text-white`
              )}
            />
          </button>
        </div>
      </div>
      <div className="border border-gray-200 border-solid shadow grid grid-cols-6">
        <div className="col-span-6 md:col-span-3 py-4 md:order-1 order-2">
          <div className="flex-wrap pl-4">
            {product?.is_thc && (
              <div className="border border-solid rounded-2xl border-hazel-orange-100 my-2 mx-1 inline-block">
                <p className="uppercase text-hazel-orange-100 py-1 px-2 text-sm font-bold">THC</p>
              </div>
            )}
            {product?.is_cbd && (
              <div className="border border-solid rounded-2xl border-hazel-light-green-800 my-2 mx-1 inline-block">
                <p className="uppercase text-hazel-light-green-800 py-1 px-2 text-sm font-bold">
                  CBD
                </p>
              </div>
            )}
            {product?.is_high_cbd && (
              <div className="border border-solid rounded-2xl border-hazel-light-green-800 my-2 mx-1 inline-block">
                <p className="uppercase text-hazel-light-green-800 py-1 px-2 text-sm font-bold">
                  HIGH CBD
                </p>
              </div>
            )}
            {product?.is_high_thc && (
              <div className="border border-solid rounded-2xl border-hazel-orange-100 my-2 mx-1 inline-block">
                <p className="uppercase text-hazel-orange-100 py-1 px-2 text-sm font-bold">
                  HIGH THC
                </p>
              </div>
            )}
          </div>
          <div className="bg-hazel-light-green-600 sm:max-w-xs w-full rounded-r-lg my-4">
            <div className="pt-4">
              {product?.product_type?.icon === 'FLOWER' && (
                <SvgFlower className="w-10 h-10 mx-6 text-hazel-solid-green-600" />
              )}
              {product?.product_type?.icon === 'OIL' && (
                <SvgOil className="w-10 h-10 mx-6 text-hazel-solid-green-600" />
              )}
              {product?.product_type?.icon === 'ORAL' && (
                <SvgCapsule className="w-10 h-10 mx-6 text-hazel-solid-green-600" />
              )}
            </div>
            <div className="py-4 px-6">
              <div className="text-white">
                <p>
                  <span className="font-bold">Type</span>: {product?.format || '-'}
                </p>
                <p>
                  <span className="font-bold">Cultivar</span>: {product?.cultivar || '-'}
                </p>
                <p>
                  <span className="font-bold">Unit size</span>:{product?.strength || '-'}
                </p>
                <p className="mt-2">
                  <span className="font-bold text-hazel-orange-100">THC</span> {product?.thc}
                </p>
                <p className="mb-2">
                  <span className="font-bold text-hazel-light-green-800">CBD</span> {product?.cbd}
                </p>
                <p className="mb-2 text-hazel-light-green-800">
                  <span>&#36;{product?.price}</span> /{' '}
                  <span className="text-sm">
                    &#36;{product?.size}
                    {product?.size_unit}
                  </span>
                </p>
                <div className="flex-wrap inline-flex gap-2">
                  <div className="bg-white rounded-2xl inline-block">
                    <p className="text-hazel-light-green-800 py-1 px-3 text-sm font-bold">
                      Category: {product?.category}
                    </p>
                  </div>
                  <div className="bg-white rounded-2xl inline-block">
                    <p className="text-hazel-light-green-800 py-1 px-3 text-sm font-bold">
                      {product?.schedule}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-4 flex items-center">
            {product?.stock && product?.stock > 0 ? (
              <CheckCircleIcon className="w-10 h-10 text-hazel-light-green-800" />
            ) : (
              <XCircleIcon className="w-10 h-10 text-red-800" />
            )}
            <div className="ml-2">
              <p className="text-hazel-light-green-800 font-bold">In Stock</p>
              <p className="text-sm">Updated: {format(new Date(Date.now()), 'd/M/y')}</p>
            </div>
          </div>
          <div className="ml-4 flex my-4 items-center">
            <SvgStar className="w-10 h-10" />
            <h3 className="font-bold ml-2 text-hazel-orange-100">Formulary</h3>

            <div className="group flex relative">
              <button>
                <QuestionMarkCircleIcon className="w-5 h-5 text-hazel-light-gray-100 ml-2 mt-1" />
              </button>
              <div
                className={clsx(
                  `group-hover:opacity-100 group-hover:visible`,
                  `transition-opacity   opacity-0`,
                  `translate-y-full -translate-x-28 sm:translate-x-3`,
                  `absolute -top-96 sm:-top-72 left-1/2`,
                  `flex justify-end`,
                  `break-all invisible`
                )}
              >
                <div className="w-56 h-44 p-4 ml-5 shadow-lg ring-1 ring-black  bg-white ring-opacity-5 rounded-md overflow-y-auto text-black text-sm"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-6 md:col-span-3 md:order-2 order-1 p-4">
          <EScriptProductImage
            image={product?.product_image ? product?.product_image : null}
            className="shadow-lg ring-1 ring-black  bg-white ring-opacity-5 h-full w-full"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ProductDetails;
