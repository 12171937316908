import React, { FC, useEffect } from 'react';
import clsx from 'clsx';
import { IProducts } from 'utils/models';
import storage from 'utils/storage';

// gql
import { useLazyQuery } from '@apollo/client';
import query from 'graphql/query';
import EScriptProductImage from './EScriptProductImage';

interface IProductInfo {
  theme?: 'default' | 'white';
  filter: {
    isFormulary?: boolean;
    isNew?: boolean;
    isPopular?: boolean;
    productIds?: string[];
    productTypeId?: string;
    search?: string;
  };
  recentViewedProducts: string[];
  setRecentViewedProducts: (products: string[]) => void;
  setCurrentSelectedProduct: (product: string) => void;
}

const themes = {
  default: {
    text: 'text-nude-100'
  },
  white: {
    text: 'text-white'
  }
};

const ProductInfo: FC<IProductInfo> = ({
  filter,
  theme = 'default',
  recentViewedProducts,
  setRecentViewedProducts,
  setCurrentSelectedProduct
}) => {
  const [getProducts, { data, loading }] = useLazyQuery<{
    products: {
      code: string;
      success: boolean;
      message: string;
      products: IProducts[];
    };
  }>(query.products, { fetchPolicy: 'network-only' });
  const products = data?.products?.products;

  // @TODO - when ready
  const handleRecentViewedProducts = (id: string) => {
    const currentIds = [...recentViewedProducts];

    if (!currentIds.includes(id)) {
      currentIds.push(id);
      setRecentViewedProducts(currentIds);
      storage.setItem('recent-ids', JSON.stringify(currentIds));
    }
  };

  useEffect(() => {
    getProducts({
      variables: {
        filter: {
          is_new: filter.isNew,
          is_popular: filter.isPopular,
          is_formulary: filter.isFormulary,
          product_ids: filter.productIds,
          product_type_id: filter.productTypeId,
          search: filter.search
        }
      }
    });
  }, [getProducts, filter]);

  const handleCurrentSelectedProduct = (id: string): void => setCurrentSelectedProduct(id);

  return (
    <div className="space-y-6">
      <div className={clsx('w-full overflow-auto', themes[theme].text)}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="px-4 relative w-full flex gap-10 snap-x snap-mandatory overflow-x-auto">
            {products && products.length > 0 ? (
              products
                .slice()
                .sort((a, b) => a.brand.localeCompare(b.brand))
                .map((item, index) => (
                  <div
                    className="snap-center shrink-0 items-center cursor-pointer"
                    key={index}
                    onClick={() => {
                      handleRecentViewedProducts(item.id);
                      handleCurrentSelectedProduct(item?.id);
                    }}
                  >
                    <div className="w-32 space-y-2">
                      <EScriptProductImage image={item.product_image} className="h-32 w-full" />
                      <div className="font-bold">
                        {item.brand} {item.product_name}
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="col-span-12">No products to show at the moment</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductInfo;
