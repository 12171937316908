import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';
import { IDispensingLimit } from 'utils/models';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import ProductDetails from 'components/ProductDetails';
import storage from 'utils/storage';

// graphql
import { useLazyQuery } from '@apollo/client';
import query from 'graphql/query';

// components
import Input from 'components/Input';
import ProductInfo from 'components/ProductInfo';
import Button from 'components/Button';
import { SvgCapsule, SvgFlower, SvgOil, SvgTopical, SvgVape } from 'components/SvgIcons';

interface IModalProductInfo {
  showRecentView?: boolean;
  showSavedProducts?: boolean;
}

const limits = {
  INTRAVAGINAL: {
    svg: <SvgFlower className="w-10 h-10 text-white" />
  },
  OIL: {
    svg: <SvgOil className="w-10 h-10 text-white" />
  },
  ORAL: {
    svg: <SvgCapsule className="w-10 h-10 text-white" />
  },
  INJECTION: {
    svg: <SvgVape className="w-10 h-10 text-white" />
  },
  TOPICAL: {
    svg: <SvgTopical className="w-10 h-10 text-white" />
  },
  VAPE_CARTRIDGE: {
    svg: <SvgVape className="w-10 h-10 text-white" />
  },
  FLOWER: {
    svg: <SvgFlower className="w-10 h-10 text-white" />
  }
};

const ModalProductInfo: FC<IModalProductInfo> = ({
  showRecentView = false,
  showSavedProducts = false
}) => {
  const [search, setSearch] = useState<string>('');
  const [searchDummy, setSearchDummy] = useState<string>('');
  const [recentViewedProducts, setRecentViewedProducts] = useState<string[]>([]);
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState<string>('');
  const [bookmarkedProducts, setBookmarkedProducts] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [productTypeFilter, setShowProductTypeFilter] = useState<string>('');

  const [getProductTypes, { data, loading }] = useLazyQuery<{
    productTypes: {
      code: string;
      message: string;
      success: boolean;
      product_types: IDispensingLimit[];
    };
  }>(query.productTypes, { fetchPolicy: 'network-only' });
  const productTypes = data?.productTypes?.product_types;

  const handleSearchDebounce = useDebouncedCallback((text: string) => {
    setSearch(text);
  }, 2000);

  useEffect(() => {
    const recentIds = storage.getItem('recent-ids');
    if (recentIds) {
      setRecentViewedProducts(JSON.parse(recentIds));
    }

    const bookmarkedIds = storage.getItem('bookmarked-ids');
    if (bookmarkedIds) {
      setBookmarkedProducts(JSON.parse(bookmarkedIds));
    }
  }, []);

  useEffect(() => {
    getProductTypes();
  }, [getProductTypes]);

  return (
    <div className="py-6 space-y-6">
      {currentSelectedProduct ? (
        <>
          <div className="py-2 px-8">
            <button
              type="button"
              className="flex w-40 h-9  items-center justify-between mb-4"
              onClick={() => setCurrentSelectedProduct('')}
            >
              <ChevronLeftIcon className="w-6 h-6 text-gray-900 text-opacity-30" />
              <span className="text-gray-900 text-opacity-30 font-black">Back to Products</span>
            </button>
            <ProductDetails
              itemId={currentSelectedProduct}
              bookmarkedProducts={bookmarkedProducts}
              setBookmarkedProducts={setBookmarkedProducts}
            />
          </div>
        </>
      ) : (
        <>
          <div className="font-bold text-3xl px-4">Medical Cannabis Products</div>
          <div className="grid gap-3 grid-cols-12">
            <div className="col-span-12">
              <div className="px-6 py-3 flex justify-between space-x-8">
                <div className="flex-1">
                  <Input
                    name="search"
                    value={searchDummy}
                    onChange={(value) => {
                      setSearchDummy(value);
                      handleSearchDebounce(value);
                    }}
                    placeholder="Search by brand, product name, type, cannabis, terpense, etc"
                    className="rounded-lg"
                  />
                </div>

                <div>
                  <Button
                    variant="red-btn-100"
                    disabled={loading}
                    rounded="lg"
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    {`${showFilters ? 'Hide' : 'Show'} Filters`}
                  </Button>
                </div>
              </div>

              {showFilters && (
                <div className="flex justify-end items-center space-x-3 px-6">
                  {productTypes?.map((item, index: number) => (
                    <div
                      key={index}
                      className={clsx(
                        productTypeFilter === item.id
                          ? 'bg-hazel-orange-100'
                          : 'bg-hazel-solid-green-600',
                        'bg-hazel-solid-green-600 p-3 rounded-md',
                        'cursor-pointer'
                      )}
                      onClick={() => {
                        if (productTypeFilter === item.id) {
                          setShowProductTypeFilter('');
                        } else {
                          setShowProductTypeFilter(item.id);
                        }
                      }}
                    >
                      {item.icon in limits && limits[item.icon].svg}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="col-span-12">
              <div className="h-[calc(100vh-15rem)] overflow-auto">
                <div className="grid gap-3 grid-cols-12">
                  {search && (
                    <div className="col-span-12">
                      <div className="p-4 space-y-2">
                        <div className="text-hazel-nude-100 text-lg font-bold">
                          Showing Results for {search}
                        </div>
                        <ProductInfo
                          filter={{ search, productTypeId: productTypeFilter }}
                          recentViewedProducts={recentViewedProducts}
                          setRecentViewedProducts={setRecentViewedProducts}
                          setCurrentSelectedProduct={setCurrentSelectedProduct}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-span-12">
                    <div className="p-4 space-y-2">
                      <div className="text-hazel-nude-100 text-lg font-bold">
                        Formularly Products
                      </div>
                      <ProductInfo
                        filter={{ isFormulary: true, productTypeId: productTypeFilter }}
                        recentViewedProducts={recentViewedProducts}
                        setRecentViewedProducts={setRecentViewedProducts}
                        setCurrentSelectedProduct={setCurrentSelectedProduct}
                      />
                    </div>
                  </div>

                  <div className="col-span-12">
                    <div className="p-4 space-y-2">
                      <div className="text-hazel-nude-100 text-lg font-bold">Popular Products</div>
                      <ProductInfo
                        filter={{ isPopular: true, productTypeId: productTypeFilter }}
                        recentViewedProducts={recentViewedProducts}
                        setRecentViewedProducts={setRecentViewedProducts}
                        setCurrentSelectedProduct={setCurrentSelectedProduct}
                      />
                    </div>
                  </div>

                  <div className="col-span-12">
                    <div className="bg-hazel-red-100 p-4 space-y-2">
                      <div className="text-white text-lg font-bold">Newly added products</div>
                      <ProductInfo
                        filter={{ isNew: true, productTypeId: productTypeFilter }}
                        theme="white"
                        recentViewedProducts={recentViewedProducts}
                        setRecentViewedProducts={setRecentViewedProducts}
                        setCurrentSelectedProduct={setCurrentSelectedProduct}
                      />
                    </div>
                  </div>

                  {showRecentView && recentViewedProducts.length > 0 && (
                    <div className="col-span-12">
                      <div className="p-4 space-y-2">
                        <div className="text-hazel-nude-100 text-lg font-bold">
                          Recently Viewed Products
                        </div>
                        <ProductInfo
                          filter={{
                            productIds: recentViewedProducts,
                            productTypeId: productTypeFilter
                          }}
                          recentViewedProducts={recentViewedProducts}
                          setRecentViewedProducts={setRecentViewedProducts}
                          setCurrentSelectedProduct={setCurrentSelectedProduct}
                        />
                      </div>
                    </div>
                  )}

                  {showSavedProducts && bookmarkedProducts.length > 0 && (
                    <div className="col-span-12">
                      <div className="p-4 space-y-2">
                        <div className="text-hazel-nude-100 text-lg font-bold">Saved Products</div>
                        <ProductInfo
                          filter={{
                            productIds: bookmarkedProducts,
                            productTypeId: productTypeFilter
                          }}
                          recentViewedProducts={recentViewedProducts}
                          setRecentViewedProducts={setRecentViewedProducts}
                          setCurrentSelectedProduct={setCurrentSelectedProduct}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ModalProductInfo;
