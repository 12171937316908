import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { IMenuTypes } from 'utils/models';
import useAuth from 'hooks/useAuth';

// components
import Header from 'components/authenticated/Header';

const OnBoardingLayout: FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const menu: IMenuTypes[] = [
    {
      label: 'Logout',
      className: 'text-gray-0',
      onClick: () => {
        logout();
        navigate('/login', { replace: true });
      }
    }
  ];

  return (
    <main className="flex flex-col h-screen bg-hazel-light-100">
      <div className="flex flex-1 overflow-hidden">
        <div className="flex flex-1 flex-col">
          <div className="flex flex-col">
            <Header menu={menu} />
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto" id="main">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
};

export default OnBoardingLayout;
