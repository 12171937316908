import gql from 'graphql-tag';
import { doctorObject, patientObject } from './object';

const query = {
  doctorMe: gql`
    query doctorMe {
      doctorMe {
        code
        success
        message
        is_from_admin
        ${doctorObject}
      }
    }
  `,
  doctorSchedule: gql`
    query doctorSchedule($from: DateTime!, $to: DateTime!) {
      doctorSchedule(from: $from, to: $to) {
        code
        success
        message
        schedules {
          id
          end_at
          start_at
        }
      }
    }
  `,
  doctorMyPatients: gql`
    query doctorMyPatients($search: String, $paginator: Paginator!) {
      doctorMyPatients(search: $search, paginator: $paginator) {
        code
        message
        total
        patients {
          email
          timezone
          contact_no
          secondary_contact_no
          id
          profile {
            first_name
            middle_name
            last_name
            pronoun
            height
            weight
            gender
            current_gender
            date_of_birth
            street
            city
            state
            postcode
            country
            postal_street
            postal_city
            postal_state
            postal_postcode
            postal_country
            medicare_no
            profile_pic
            careteam_notes
            careteam_notes_last_edit_by
            careteam_notes_last_edit_at
            patient_notes
            communication_preference
          }
          background {
            approach_your_health
            diagnosed_health_condition
            diagnosed_health_condition_others
            gastro
            health_goals
            health_goals_others
            hear_about
            interested_speciality
            menopausal
            menstruation
            mood
            other
            other_symptoms
            pain
            sexual_health
            undiagnosed_health_condition
            vaginal_health
            virtual_not_appropriate_reason
            what_brings_you_to_hazel
            whatelse_interested
            whatelse_interested_others
          }
          health_history {
            has_diagnosed_reproductive_condition
            diagnosed_conditions
            symptoms
            any_other_condition_aware_of_derma
            any_other_condition_aware_of_mental
            any_other_condition_aware_of_cardio
            any_other_condition_aware_of_muskolo
            any_other_condition_aware_of_gastro
            any_other_condition_aware_of_urinary
            any_other_condition_aware_of_metabolic
            any_other_condition_aware_of_auto_immunity
            any_other_condition_aware_of_nuero
            any_other_condition_aware_of_reproductive
            impact_daily_life_personal
            impact_daily_life_mental
            impact_daily_life_financial
            impact_daily_life_physical
            seeing_any_professional
            seen_practitioner_others
            investigation_surgical_intervention
            natural_therapies_alternatives
            taking_any_medication
            have_allergies
            last_pap_smear
            do_you_smoke
            do_you_drink
            mens_cycle_frequency
            mens_period_last
            mens_flow_volume
            mens_blood_color
            mens_apperance
            mens_pain_level
            other_notes
            status
          }
          billing_address {
            street
            city
            state
            postcode
          }
          delivery_address {
            street
            city
            state
            postcode
          }
          phone_verification
          email_verification
          id_verification
          status
          consent_approved
          shop_consent_approved
          same_delivery
          same_billing
          wallet_balance
          checkout_enabled
          onboarding_step
          recent_consultation_id
          recent_reference_id
          consultation_status
          last_consultation_date
          last_consultation_date_end
        }
      }
    }
  `,
  consultationDoctorUpcoming: gql`
    query consultationDoctorUpcoming {
      consultationDoctorUpcoming {
        code
        success
        message
        consultation {
          id
          reference_id
          notes
          ${patientObject}
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
          }
          enquiry
          start_at
          end_at
        }
      }
    }
  `,
  consultationDoctorPatientUpcoming: gql`
    query consultationDoctorPatientUpcoming($patientId: String!) {
      consultationDoctorPatientUpcoming(patient_id: $patientId) {
        code
        success
        message
        consultations {
          id
          reference_id
          notes
          ${patientObject}
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
          }
          enquiry
          start_at
          end_at
        }
      }
    }
  `,
  doctorSummary: gql`
    query doctorSummary {
      doctorSummary {
        code
        message
        summary {
          month_appointment
          new_message
          total_consultation
          total_patient
          week_appointment
          active_patient
          inactive_patient
        }
      }
    }
  `,
  consultationDoctorsListByDate: gql`
    query consultationDoctorsListByDate($from: DateTime!, $to: DateTime!) {
      consultationDoctorsListByDate(from: $from, to: $to) {
        code
        message
        success
        consultations {
          id
          reference_id
          notes
          ${patientObject}
          start_at
          end_at
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
          }
          enquiry
        }
      }
    }
  `,
  doctorConsultations: gql`
    query doctorConsultations(
      $paginator: Paginator!
      $date_from: DateTime
      $date_to: DateTime
      $search: String
    ) {
      doctorConsultations(
        paginator: $paginator
        date_from: $date_from
        date_to: $date_to
        search: $search
      ) {
        code
        success
        message
        count
        consultations {
          id
          reference_id
          notes
          ${patientObject}
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
          }
          status
          enquiry
        }
      }
    }
  `,
  products: gql`
    query products($filter: ProductFilter) {
      products(filter: $filter) {
        code
        success
        message
        products {
          id
          type
          brand
          product_name
          product_image
          stock
          format
          schedule
          price
          is_active
        }
      }
    }
  `,
  consultationDetails: gql`
    query consultationDetails($id: String!) {
      consultationDetails(id: $id) {
        code
        success
        message
        consultation {
          id
          reference_id
          notes
          ${doctorObject}
          ${patientObject}
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            default_clinical_notes
          }
          status
          enquiry
          tga_approval
          tga_acceptance
          script_approval
          script_disabled
          documents {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
          can_finish
        }
      }
    }
  `,
  patientPrescription: gql`
    query patientPrescription($id: String!) {
      patientPrescription(id: $id) {
        code
        success
        message
        prescription {
          id
          patient_id
          consultation_id
          products {
            prescription_id
            product_id
            no_of_units
            repeats
            dosage
            frequency
            route
          }
          custom_products {
            product_name
            product_details
            product_type
            no_of_units
            repeats
            dosage
            brand_name
            sku
            type
            format
            frequency
            route
          }
          consultation_notes
          treatment_notes
          careteam_notes
          with_escript
          followup_period
          followup_value
          set_followup
          dispensing_interval
        }
      }
    }
  `,
  patientOtherConsultations: gql`
    query patientOtherConsultations($patient_id: String!, $consultation_id: String!) {
      patientOtherConsultations(patient_id: $patient_id, consultation_id: $consultation_id) {
        code
        success
        message
        consultations {
          consultation_ref_id
          consultation_status
          consultation_date
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
          }
          prescription {
            id
            patient_id
            consultation_id
            products {
              prescription_id
              product_id
              product {
                id
                type
                brand
                product_name
                stock
                format
                schedule
                price
                is_active
              }
              no_of_units
              repeats
              dosage
              frequency
              route
            }
            custom_products {
              product_name
              product_details
              product_type
              no_of_units
              repeats
              dosage
              brand_name
              sku
              type
              format
              frequency
              route
            }
            consultation_notes
            treatment_notes
            careteam_notes
            with_escript
            consultation {
              id
              reference_id
              notes
              patient {
                id
                email
                timezone
                contact_no
                profile {
                  patient_id
                  first_name
                  middle_name
                  last_name
                  pronoun
                  gender
                  date_of_birth
                  street
                  city
                  state
                  postcode
                  country
                  medicare_no
                  profile_pic
                  is_australian_resident
                  is_over_18
                  tried_conventional_treatment
                  medical_symptoms
                  medical_conditions
                  conditions
                  other_conditions
                  how_long_suffered
                  using_any_medicine
                  have_any_allergy
                  allergy_details
                  relevant_notes
                  onboarding_step
                  about_polln
                  currently_being_treated
                  current_prescription
                  taking_any_medication
                  taking_any_medication_list
                  achieve_polln
                  addtl_info
                  patient_notes
                }
                health_history {
                  patient_id
                  english_language
                  primary_language
                  video_comfortable
                  heart_lung_condition
                  mental_neuro_condition
                  muscle_condition
                  other_condition
                  other_condition_list
                  currently_taking_medications
                  current_medications
                  medicine_with_prescription
                  medicine_with_prescription_details
                  medicine_without_prescription
                  medicine_without_prescription_details
                  smoke_tobacco
                  smoke_tobacco_frequency
                  drink_alcohol
                  drink_alcohol_frequency
                  pregnant_breastfeeding
                  psychiatric_disorder
                  family_with_health_condition
                  family_with_health_condition_details
                  injury_surgery__hospitalization
                  injury_surgery__hospitalization_details
                  current_diet
                  physical_activity_level
                  stress_emotions
                  status
                }
                background {
                  patient_id
                  cannabis_used
                  have_medical_prescription
                  cannabis_history
                  has_allergy
                  allergy
                  allergy_severity
                  has_surgery
                  surgery
                  surgery_date
                  alcohol_used
                  tobacco_used
                  exescise_activity
                  family_history
                  other_family_history
                  medical_history
                  other_medical_history
                  currently_being_treated
                  current_clinic
                  current_doctor
                  current_date
                  currently_taking_medication
                  current_medication
                  current_medication_dose
                  current_work_status
                  current_work
                }
              }
              ${doctorObject}
              start_at
              end_at
              consultation_type {
                id
                name
                capitalize_name
                duration
                is_default
              }
              status
              enquiry
              tga_approval
              tga_acceptance
              script_approval
              script_disabled
              documents {
                filename
                file_url
                file_key
                uploaded_by
                file_type
              }
            }
          }
          script {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
        }
      }
    }
  `,
  patientPreviousPrescription: gql`
    query patientPreviousPrescription($patient_id: String!, $consultation_id: String!) {
      patientPreviousPrescription(patient_id: $patient_id, consultation_id: $consultation_id) {
        code
        message
        success
        prescription {
          id
          patient_id
          consultation_id
          products {
            prescription_id
            product_id
            product {
              id
              type
              brand
              product_name
              stock
              format
              schedule
              price
              is_active
            }
            no_of_units
            repeats
            dosage
            frequency
            route
          }
          custom_products {
            product_name
            product_details
            product_type
            no_of_units
            repeats
            dosage
            brand_name
            sku
            type
            format
            frequency
            route
          }
          consultation_notes
          treatment_notes
          careteam_notes
          with_escript
          followup_period
          followup_value
          set_followup
          dispensing_interval
          consultation {
            id
            reference_id
            notes
            ${patientObject}
            ${doctorObject}
            start_at
            end_at
            consultation_type {
              id
              name
              capitalize_name
              duration
              is_default
            }
            status
            enquiry
            tga_approval
            tga_acceptance
            script_approval
            script_disabled
            documents {
              filename
              file_url
              file_key
              uploaded_by
              file_type
            }
          }
        }
      }
    }
  `,
  doctorCalendar: gql`
    query doctorCalendar($from: DateTime!, $to: DateTime!) {
      doctorCalendar(from: $from, to: $to) {
        code
        success
        message
        schedules {
          id
          recurring_id
          start_at
          end_at
        }
        consultations {
          id
          reference_id
          notes
          ${patientObject}
          start_at
          end_at
          consultation_type {
            id
            name
            price
            color
            duration
            capitalize_name
          }
          status
          enquiry
        }
      }
    }
  `,
  consultationDocuments: gql`
    query consultationDocuments($id: String!) {
      consultationDocuments(id: $id) {
        code
        success
        message
        documents {
          filename
          file_url
          file_key
          uploaded_by
          file_type
          upload_date
          consultation {
            id
            reference_id
            notes
            ${patientObject}
            ${doctorObject}
            start_at
            end_at
            consultation_type {
              id
              name
              capitalize_name
              duration
              is_default
            }
            status
            enquiry
            tga_approval
            tga_acceptance
            script_approval
            script_disabled
            documents {
              filename
              file_url
              file_key
              uploaded_by
              file_type
            }
          }
          visibility
        }
      }
    }
  `,
  consultationEscripts: gql`
    query consultationEscripts($id: String!) {
      consultationEscripts(id: $id) {
        code
        success
        message
        escripts {
          id
          ${patientObject}
          ${doctorObject}
          consultation_date
          product {
            id
            type
            brand
            product_name
            stock
            format
            schedule
            price
            is_active
          }
          reference_id
          description
          remaining
          total
          status
        }
      }
    }
  `,
  consultationPrescription: gql`
    query consultationPrescription($id: String!) {
      consultationPrescription(id: $id) {
        code
        success
        message
        prescription {
          id
          consultation_id
          products {
            prescription_id
            product_id
            product {
              id
              type
              brand
              product_name
              stock
              format
              schedule
              price
              is_active
            }
            no_of_units
            repeats
            dosage
            frequency
            route
          }
          supplements {
            prescription_id
            supplement_id
            supplement {
              id
              sku
              name
              image_url
              stock
              stock_last_updated
              price
              days_to_expire
              status
            }
            dosage
          }
          # lifestyles {
          #   prescription_id
          #   advice
          #   other_advice
          #   duration
          #   frequency
          # }
          referrals {
            prescription_id
            type
            other_type
            timeframe
            other_timeframe,
            document
          }
          consultation_notes
          careteam_notes
          naturophaty_recommendation
          mental_recommendation
          dietician_recommendation
          sexual_recommendation
          lifestyle_recommendation
          patient_notes
          dispensing_interval
          dispensing_interval_reason
          consultation {
            id
            reference_id
            notes
            ${patientObject}
            ${doctorObject}
            start_at
            end_at
            consultation_type {
              id
              name
              capitalize_name
              duration
              is_default
            }
            status
            enquiry
            tga_approval
            tga_acceptance
            script_approval
            script_disabled
            documents {
              filename
              file_url
              file_key
              uploaded_by
              file_type
            }
          }
          clinical_notes {
            note
            type
          }
        }
      }
    }
  `,
  patientDocuments: gql`
    query patientDocuments($id: String) {
      patientDocuments(id: $id) {
        code
        success
        message
        documents {
          filename
          file_url
          file_key
          uploaded_by
          file_type
          upload_date
          consultation {
            id
            reference_id
            notes
            ${patientObject},
            ${doctorObject}
            start_at
            end_at
            consultation_type {
              id
              name
              capitalize_name
              duration
              is_default
            }
            status
            enquiry
            tga_approval
            tga_acceptance
            script_approval
            script_disabled
            documents {
              filename
              file_url
              file_key
              uploaded_by
              file_type
            }
          }
          visibility
        }
      }
    }
  `,
  doctorUpcomingConsultations: gql`
    query doctorUpcomingConsultations($count: Int!) {
      doctorUpcomingConsultations(count: $count) {
        code
        success
        message
        count
        consultations {
          id
          reference_id
          notes
          ${patientObject}
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
          }
          enquiry
          start_at
          end_at
          status
        }
      }
    }
  `,
  doctorRecentConsultations: gql`
    query doctorRecentConsultations($count: Int!) {
      doctorRecentConsultations(count: $count) {
        code
        success
        message
        count
        consultations {
          id
          reference_id
          notes
          prescription {
            consultation_notes
            careteam_notes
          }
          ${patientObject}
          ${doctorObject}
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
          }
          enquiry
          start_at
          end_at
          status
        }
      }
    }
  `,
  doctorConsultationSummary: gql`
    query doctorConsultationSummary {
      doctorConsultationSummary {
        code
        message
        success
        data {
          available
          booked
          percentage
        }
      }
    }
  `,
  doctorEscripts: gql`
    query doctorEscripts($count: Int!, $status: [EScriptStatus], $is_expiring: Boolean) {
      doctorEscripts(count: $count, status: $status, is_expiring: $is_expiring) {
        code
        message
        success
        escripts {
          id
          ${patientObject}
          ${doctorObject}
          consultation_date
          product {
            id
            type
            brand
            product_name
            stock
            format
            schedule
            price
            is_active
          }
          reference_id
          description
          remaining
          total
          status
          tokens {
            id
            status
          }
        }
      }
    }
  `,
  patientProfile: gql`
    query patientProfile($id: String!) {
      patientProfile(id: $id) {
        code
        message
        success
        is_from_admin
        ${patientObject}
      }
    }
  `,
  patientConsultations: gql`
    query patientConsultations(
      $id: String
      $paginator: Paginator
      $date_from: DateTime
      $date_to: DateTime
      $status: [ConsultationStatus]
    ) {
      patientConsultations(
        id: $id
        paginator: $paginator
        date_from: $date_from
        date_to: $date_to
        status: $status
      ) {
        code
        message
        success
        count
        consultations {
          id
          reference_id
          ${patientObject}
          notes
          ${doctorObject}
          start_at
          end_at
          prescription {
            clinical_notes {
              note
              type
              created_at
            }
            careteam_notes
          }
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
          }
          status
          enquiry
          tga_approval
          tga_acceptance
          script_approval
          script_disabled
          can_finish
        }
      }
    }
  `,
  patientEscripts: gql`
    query patientEscripts($id: String, $is_history: Boolean) {
      patientEscripts(id: $id, is_history: $is_history) {
        code
        message
        success
        prescriptions {
          prescription_id
          product_id
          product {
            id
            product_name
            price
            strength
            format
            description
            image_url
            type
            brand
          }
          dosage
          frequency
          no_of_units
          repeats
          route
          remaining
          doctor {
            first_name
            last_name
          }
        }
        supplements {
          dosage
          prescription_id
          supplement_id
          supplement {
            days_to_expire
            id
            name
            price
            stock
          }
        }
        # lifestyles {
        #   prescription_id
        #   advice
        #   duration
        #   frequency
        # }
        referrals {
          prescription_id
          type
          timeframe
        }
        script_naturophaty {
          naturophaty_recommendation
          doctor {
            first_name
            last_name
          }
        }
        script_dietitian {
          dietician_recommendation
          doctor {
            first_name
            last_name
          }
        }
        script_mental {
          mental_recommendation
          doctor {
            first_name
            last_name
          }
        }
        script_sexual {
          sexual_recommendation
          doctor {
            first_name
            last_name
          }
        }
        script_lifestyle {
          lifestyle_recommendation
          doctor {
            first_name
            last_name
          }
        }
      }
    }
  `,
  patientDispensingLimits: gql`
    query patientDispensingLimits($id: String!) {
      patientDispensingLimits(id: $id) {
        code
        message
        success
        data {
          dispensing_limit
          icon
          id
          name
          unit
        }
      }
    }
  `,
  consultationHistoryNotes: gql`
    query consultationPatientHistory($patient_id: String, $consultation_id: String) {
      consultationPatientHistory(patient_id: $patient_id, consultation_id: $consultation_id) {
        code
        message
        success
        consultations {
          reference_id
          start_at
          end_at
          notes
        }
      }
    }
  `,
  productTypes: gql`
    query productTypes {
      productTypes {
        code
        message
        success
        product_types {
          dispensing_limit
          icon
          id
          name
          unit
        }
      }
    }
  `,
  consultationTypes: gql`
    query consultationTypes($doctorTypeId: String!) {
      consultationTypes(doctorTypeId: $doctorTypeId) {
        code
        success
        message
        consultation_types {
          id
          name
          color
          capitalize_name
          default_clinical_notes
          duration
          price
          is_default
          is_preselected
          description
        }
      }
    }
  `,
  productDetails: gql`
    query productDetails($id: String!) {
      productDetails(id: $id) {
        code
        message
        success
        product {
          id
          product_name
          product_image
          schedule
          description
          category
          stock
          price
          format
          is_active
          type
          size
          size_unit
          weight
          thc
          cbd
          is_thc
          is_cbd
          is_high_cbd
          is_high_thc
          product_type {
            dispensing_limit
            unit
            icon
          }
          updated_at
          cultivar
          strength
        }
      }
    }
  `,
  doctorNotifications: gql`
    query doctorNotifications {
      doctorNotifications {
        code
        message
        success
        notifications {
          content
          id
          is_read
          link
          title
          created_at
        }
      }
    }
  `,
  patientDispensingLimitsHistory: gql`
    query patientDispensingLimitsHistory($id: String!) {
      patientDispensingLimitsHistory(id: $id) {
        code
        message
        success
        data {
          clinical_notes
          created_at
          ${doctorObject}
          new_dispensing_limit
          old_dispensing_limit
          product_type {
            dispensing_limit
            icon
            id
            name
            unit
          }
        }
      }
    }
  `,
  escriptsProducts: gql`
    query escriptsProducts($filter: ProductFilter) {
      escriptsProducts(filter: $filter) {
        code
        success
        message
        products {
          id
          type
          brand
          product_name
          product_image
          stock
          format
          schedule
          price
          is_active
        }
      }
    }
  `,
  escriptsSupplements: gql`
    query escriptsSupplements {
      escriptsSupplements {
        code
        success
        message
        supplements {
          id
          sku
          name
          image_url
          stock
          stock_last_updated
          price
          days_to_expire
          status
        }
      }
    }
  `,
  escriptsVoidAttempts: gql`
    query escriptsVoidAttempts($consultation_id: String!) {
      escriptsVoidAttempts(consultation_id: $consultation_id) {
        code
        success
        message
        products {
          script_product_id
          reason
        }
        supplements {
          script_supplement_id
          reason
        }
        # lifestyles {
        #   script_lifestyle_id
        #   reason
        # }
        referrals {
          script_referral_id
          reason
        }
        naturopath_recommendations {
          script_id
          doctor
          reason
        }
        dietician_recommendations {
          script_id
          doctor
          reason
        }
        sexual_recommendations {
          script_id
          doctor
          reason
        }
        mental_recommendations {
          script_id
          doctor
          reason
        }
        lifestyle_recommendations {
          script_id
          doctor
          reason
        }
      }
    }
  `,
  doctorTypes: gql`
    query doctorTypes {
      doctorTypes {
        code
        message
        success
        types {
          description
          id
        }
      }
    }
  `,
  patientCareplan: gql`
    query patientCareplan($id: String, $type: CareplanType, $is_history: Boolean) {
      patientCareplan(id: $id, type: $type, is_history: $is_history) {
        code
        data {
          notes {
            created_at
            doctor {
              first_name
              last_name
              profile_pic
            }
            recommendation
            script_id
          }
          clinical_notes {
            notes {
              created_at
              doctor {
                first_name
                last_name
                profile_pic
              }
              note
              script_id
            }
            type
          }
          dietician_recommendation {
            created_at
            doctor {
              first_name
              last_name
              profile_pic
            }
            recommendation
            script_id
          }
          # lifestyles {
          #   advice
          #   duration
          #   frequency
          #   other_advice
          #   prescription_id
          # }
          mental_recommendation {
            created_at
            doctor {
              first_name
              last_name
              profile_pic
            }
            recommendation
            script_id
          }
          naturophaty_recommendation {
            created_at
            doctor {
              first_name
              last_name
              profile_pic
            }
            recommendation
            script_id
          }
          products {
            created_at
            doctor {
              first_name
              last_name
            }
            prescription_id
            product_id
            product {
              id
              type
              brand
              product_name
              product_type {
                dispensing_limit
                icon
                id
                name
                unit
              }
              stock
              format
              schedule
              price
              is_active
            }
            # product_image
            no_of_units
            frequency
            repeats
            dosage
          }
          referrals {
            doctor_name
            prescription_id
            timeframe
            type
            other_type
            other_timeframe
            document
            referral_date
          }
          sexual_recommendation {
            created_at
            doctor {
              first_name
              last_name
              profile_pic
            }
            recommendation
            script_id
          }
          lifestyle_recommendation {
            created_at
            doctor {
              first_name
              last_name
              profile_pic
            }
            recommendation
            script_id
          }
          supplements {
            created_at
            doctor {
              first_name
              last_name
              profile_pic
            }
            dosage
            prescription_id
            supplement_id
            supplement {
              days_to_expire
              id
              name
              price
              stock
            }
          }
        }
        message
        success
      }
    }
  `,
  escriptsProductTypes: gql`
    query escriptsProductTypes {
      escriptsProductTypes {
        code
        message
        success
        product_types {
          dispensing_limit
          icon
          id
          unit
          svg
          name
        }
      }
    }
  `,
  consultationPatientUpcoming: gql`
    query consultationPatientUpcoming($count: Int) {
      consultationPatientUpcoming(count: $count) {
        code
        message
        success
        consultations {
          id
          reference_id
          notes
          ${doctorObject}
          ${patientObject}
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            default_clinical_notes
          }
          status
          enquiry
          tga_approval
          tga_acceptance
          script_approval
          script_disabled
          documents {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
          can_finish
        }
      }
    }
  `
};

export default query;
