import { saveAs } from 'file-saver';
import {
  format,
  differenceInYears,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  nextSunday,
  startOfDay,
  parseISO,
  isBefore
} from 'date-fns';
import * as Sentry from '@sentry/browser';
import { TCalendarView, TGenders } from 'utils/types';
import {
  GENDER_OPTIONS,
  LIFESTYLE_ADVICE,
  REFERRAL_TIMEFRAME,
  REFERRAL_TYPE
} from 'utils/constants';
import {
  ICreateSchedule,
  IDoctorDateSchedule,
  IDoctorDetails,
  IFileCheckResponse,
  IMedicationProps,
  IPatientBackground,
  IPatientData,
  IPatientDetails,
  IPatientHistory,
  ISelectOptions
} from 'utils/models';
import PhotoPlaceholderDoctor from 'assets/images/placeholder-doctor.png';
import PhotoPlaceholderPatient from 'assets/images/placeholder-upload.jpg';
import { isPlainObject } from 'is-plain-object';
import { CONF_API_URL } from './config';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export const ensureMediaPermissionsWithDetails = async (
  name: string,
  consultationId: string | undefined,
  referenceId: string | undefined
) => {
  return await navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()))
          .catch((error) => {
            Sentry.captureMessage(
              `Doctor ${name} denied camera and audio permission for consultation ID: ${consultationId} with reference ID: ${referenceId}, ERROR: ${error}`
            );

            return error;
          });
      }
    });
};

export const ensureMediaPermissions = async () => {
  return await navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()))
          .catch((error) => {
            Sentry.captureMessage(`Doctor denied camera and audio permission. ERROR: ${error}`);

            return error;
          });
      }
    });
};

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

export const range = (start: number, end: number) =>
  Array.from(Array(Math.abs(end - start) + 1), (_, i) => start + i);

export const countDown = (timer: number) => {
  const totalSeconds = (timer %= 3600);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return {
    minutes,
    seconds
  };
};

export const fileToObjectUrl = (image: File | string) =>
  image instanceof File ? URL.createObjectURL(image) : image;

export const getGender = (gender: TGenders, genderOther: string) => {
  const genderLabel = GENDER_OPTIONS.find((item: ISelectOptions) => item.value === gender);
  return gender === 'OTHERS' ? genderOther : genderLabel?.label;
};

export const getDobAge = (dateOfBirth: Date) => {
  const dob = format(new Date(dateOfBirth), 'dd/MM/yyy');
  const age = differenceInYears(new Date(), new Date(dateOfBirth));

  return {
    dob,
    age,
    dobAge: `${dob} - ${age} y`
  };
};

export const getDateOrdinal = (date: any) => {
  const curDate = new Date(date);
  const day = curDate.getDate();
  const month = curDate.toLocaleString('default', { month: 'long' });
  const year = curDate.getFullYear();

  const nthNumber = (number: number) => {
    if (number > 3 && number < 21) return 'th';
    switch (number % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const newDate = `${day}${nthNumber(day)} ${month}, ${year}`;

  return newDate;
};

export const getConditions = (user: IPatientDetails | null) => {
  let conditions = '-';

  if (user) {
    let arrConditions: string[] = [];
    if (user.profile.conditions) {
      arrConditions = JSON.parse(user.profile.conditions);
    }

    if (user.profile.other_conditions) arrConditions.push(user.profile.other_conditions);
    conditions = arrConditions.join(', ');
  }

  return conditions;
};

export const getMedicalHistory = (history: string, others: string) => {
  let medicalHistory = '-';

  if (history || others) {
    let arrHistory: string[] = [];
    if (history) {
      arrHistory = JSON.parse(history);
    }

    if (others) arrHistory.push(others);
    medicalHistory = arrHistory.join(', ');
  }

  return medicalHistory;
};

export const getProfilePicInput = (profilePic: string | null | undefined) => {
  let profilePicUrl = profilePic;

  if (profilePic && profilePic.includes('https://')) {
    const split = profilePic.split('/');
    const splitSource = split[4].split('?');
    profilePicUrl = `${split[3]}/${splitSource[0]}`;
  }

  return profilePicUrl;
};

export const fileChecker = ({
  file,
  fileTypes = [],
  fileSizeLimit
}: {
  file: File;
  fileTypes: string[];
  fileSizeLimit: number;
}) => {
  let response: IFileCheckResponse = {
    success: true,
    message: ''
  };

  if (file) {
    // @ts-ignore
    const ext = file.name.split('.').pop().toLowerCase();
    const validFileTypes = fileTypes;

    if (validFileTypes.includes(ext)) {
      const sizeLimit = fileSizeLimit / 1024;
      const sizeLimitByte = fileSizeLimit * 1000;
      const decimals = '';
      const k = 1024;
      const dm: number = decimals || 2;
      const sizes: string[] = ['Bytes', 'KB', 'MB'];
      const bigSizes: any = ['GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(file.size) / Math.log(k));
      const iC = Math.floor(Math.log(sizeLimitByte) / Math.log(k));
      const fileSize = parseFloat((file.size / Math.pow(k, i)).toFixed(dm));

      if ((fileSize > sizeLimit && sizes[i] === 'MB') || bigSizes.includes(sizes[i], bigSizes)) {
        const fSize = sizes[i];
        const cSize = sizes[iC];
        const limit = iC > 1 ? Math.round(sizeLimit) : fileSizeLimit;
        response = {
          success: false,
          message: `${file.name} has ${fileSize}${fSize} of file size. Please limit file size to ${limit}${cSize} and under only.`
        };
      }
    } else {
      response = {
        success: false,
        message: `${file.name} is not a valid file. We only accept ${validFileTypes.join(', ')}.`
      };
    }
  }

  return response;
};

export const checkValidNumber = (input: any) => input.match(/^[0-9]+$/);

export const getRangeDates = (start: Date, end: Date) => {
  const date = new Date(start.getTime());
  const dates = [];

  while (date <= end) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const groupBy = (array: any[], f: any) => {
  // eslint-disable-next-line no-sequences
  return array.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
};

export const photoPlaceholder: {
  patient: string;
  doctor: string;
} = {
  patient: PhotoPlaceholderPatient,
  doctor: PhotoPlaceholderDoctor
};

export const fullName = (user: IPatientDetails | IDoctorDetails | IPatientData | null) =>
  user ? `${user?.profile?.first_name || '-'} ${user?.profile?.last_name || '-'}` : '-';

export const calendarDate = ({
  date,
  view,
  dateFormat = 'dd, MMMM yyyy'
}: {
  date: Date;
  view?: TCalendarView;
  dateFormat?: string;
}) => {
  let formatDate = format(date, dateFormat);
  let from = format(date, 'yyyy-MM-dd 00:00:00');
  let to = format(date, 'yyyy-MM-dd 23:59:59');

  if (view === 'dayGridMonth') {
    formatDate = `${format(startOfMonth(date), dateFormat)} - ${format(
      endOfMonth(date),
      dateFormat
    )}`;

    from = format(startOfMonth(date), 'yyyy-MM-dd 00:00:00');
    to = format(endOfMonth(date), 'yyyy-MM-dd 23:59:59');
  }

  if (view === 'timeGridWeek') {
    formatDate = `${format(startOfWeek(date, { weekStartsOn: 1 }), dateFormat)} - ${format(
      endOfWeek(date, { weekStartsOn: 1 }),
      dateFormat
    )}`;

    from = format(startOfWeek(date), 'yyyy-MM-dd 00:00:00');
    to = format(endOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd 23:59:59');
  }

  return {
    formatDate,
    fromUtc: new Date(from).toISOString(),
    from,
    to,
    toUtc: new Date(to).toISOString()
  };
};

export const getAddress = (user: IPatientDetails | IDoctorDetails | null) =>
  user?.profile?.street && user?.profile?.state && user?.profile?.city && user?.profile?.postcode
    ? `${user?.profile?.street || '-'} ${user?.profile?.city || '-'}, ${
        user?.profile?.state || '-'
      }, ${user?.profile?.postcode || '-'}`
    : '';

export const downloadFile = (fileName: string, fileKey: string) =>
  saveAs(`${CONF_API_URL}/download?key=${fileKey}`, fileName);

export const formatId = (value: number, size: number) => {
  let s = value + '';
  while (s.length < size) s = '0' + s;
  return s;
};

export const checkBooleanString = (value?: string) => ({
  stringValue: value ? (JSON.parse(value) ? 'Yes' : 'No') : '-',
  isTrue: value ? JSON.parse(value) : false
});

export const checkBoolean = (value?: 1 | 2) => ({
  stringValue: value ? (value === 1 ? 'Yes' : 'No') : '-',
  isTrue: value ? (value === 1 ? true : false) : false
});

export const getHealthCondition = (healthHistory?: IPatientHistory) => ({
  diagnosed_conditions: healthHistory?.diagnosed_conditions
    ? JSON.parse(healthHistory.diagnosed_conditions).map((item: any) => {
        return item.value;
      })
    : ([] as string[]),
  medication_list: healthHistory?.medication_list
    ? JSON.parse(healthHistory.medication_list).map((item: any) => {
        return `<div>
        <span className="font-semibold">${item.name} - </span>
        <span>${item.dosage} | </span>
        <span>${item.frequency} | </span>
        <span>${item.reason}</span>
        </div>`;
      })
    : ([] as string[]),
  practitioner_contact_detail: healthHistory?.practitioner_contact_details
    ? JSON.parse(healthHistory.practitioner_contact_details).map((item: any) => {
        return `<div>
             <span className="font-semibold">${item.name} - </span>
             <span>${item.specialty}</span>
            <span>${item.contact}</span>
        </div>`;
      })
    : ([] as string[])
});

export const getPreExistingConditions = (healthHistory?: IPatientHistory) => ({
  any_other_condition_aware_of_auto_immunity:
    healthHistory?.any_other_condition_aware_of_auto_immunity
      ? (JSON.parse(healthHistory.any_other_condition_aware_of_auto_immunity) as string[])
      : ([] as string[]),
  any_other_condition_aware_of_cardio: healthHistory?.any_other_condition_aware_of_cardio
    ? (JSON.parse(healthHistory.any_other_condition_aware_of_cardio) as string[])
    : ([] as string[]),
  any_other_condition_aware_of_derma: healthHistory?.any_other_condition_aware_of_derma
    ? (JSON.parse(healthHistory.any_other_condition_aware_of_derma) as string[])
    : ([] as string[]),
  any_other_condition_aware_of_gastro: healthHistory?.any_other_condition_aware_of_gastro
    ? (JSON.parse(healthHistory.any_other_condition_aware_of_gastro) as string[])
    : ([] as string[]),
  any_other_condition_aware_of_mental: healthHistory?.any_other_condition_aware_of_mental
    ? (JSON.parse(healthHistory.any_other_condition_aware_of_mental) as string[])
    : ([] as string[]),
  any_other_condition_aware_of_metabolic: healthHistory?.any_other_condition_aware_of_metabolic
    ? (JSON.parse(healthHistory.any_other_condition_aware_of_metabolic) as string[])
    : ([] as string[]),
  any_other_condition_aware_of_muskolo: healthHistory?.any_other_condition_aware_of_muskolo
    ? (JSON.parse(healthHistory.any_other_condition_aware_of_muskolo) as string[])
    : ([] as string[]),
  any_other_condition_aware_of_nuero: healthHistory?.any_other_condition_aware_of_nuero
    ? (JSON.parse(healthHistory.any_other_condition_aware_of_nuero) as string[])
    : ([] as string[]),
  any_other_condition_aware_of_reproductive:
    healthHistory?.any_other_condition_aware_of_reproductive
      ? (JSON.parse(healthHistory.any_other_condition_aware_of_reproductive) as string[])
      : ([] as string[]),
  any_other_condition_aware_of_urinary: healthHistory?.any_other_condition_aware_of_urinary
    ? (JSON.parse(healthHistory.any_other_condition_aware_of_urinary) as string[])
    : ([] as string[]),
  natural_therapies_alternatives: healthHistory?.natural_therapies_alternatives
    ? (JSON.parse(healthHistory.natural_therapies_alternatives) as string[])
    : ([] as string[]),
  investigation_surgical_intervention: healthHistory?.investigation_surgical_intervention
    ? (JSON.parse(healthHistory.investigation_surgical_intervention) as string[])
    : ([] as string[]),
  mens_blood_color: healthHistory?.mens_blood_color
    ? (JSON.parse(healthHistory.mens_blood_color) as string[])
    : ([] as string[]),
  mens_apperance: healthHistory?.mens_apperance
    ? (JSON.parse(healthHistory.mens_apperance) as string[])
    : ([] as string[]),
  investigation_surgical_surgeries: healthHistory?.investigation_surgical_surgeries
    ? (JSON.parse(healthHistory.investigation_surgical_surgeries) as string[])
    : ([] as string[]),
  medication_list: healthHistory?.medication_list
    ? (JSON.parse(healthHistory.medication_list) as IMedicationProps[])
    : ([] as string[])
});

export const getMedicineDetails = (healthHistory?: IPatientHistory) => ({
  // withPrescription: healthHistory?.medicine_with_prescription_details
  //   ? `Frequency: ${
  //       (JSON.parse(healthHistory.medicine_with_prescription_details) as IMedicineDetails).frequency
  //     }, Type: ${
  //       (JSON.parse(healthHistory.medicine_with_prescription_details) as IMedicineDetails).type
  //     }`
  //   : '-',
  // withoutPrescription: healthHistory?.medicine_without_prescription_details
  //   ? `Frequency: ${
  //       (JSON.parse(healthHistory.medicine_without_prescription_details) as IMedicineDetails)
  //         .frequency
  //     }, Type: ${
  //       (JSON.parse(healthHistory.medicine_without_prescription_details) as IMedicineDetails).type
  //     }`
  //   : '-'
  medication: healthHistory?.medication_list
    ? `Name: ${(JSON.parse(healthHistory.medication_list) as IMedicationProps).name},
     Dosage: ${(JSON.parse(healthHistory.medication_list) as IMedicationProps).dosage}
      Frequency: ${(JSON.parse(healthHistory.medication_list) as IMedicationProps).frequency}
      Reason: ${(JSON.parse(healthHistory.medication_list) as IMedicationProps).reason}`
    : '-'
});

export const getHolisticHealth = (healthHistory?: IPatientHistory) => ({
  // diet: healthHistory?.current_diet ? healthHistory?.current_diet : '-',
  // activityLevel: healthHistory?.physical_activity_level
  //   ? healthHistory?.physical_activity_level
  //   : '-',
  // emotions: healthHistory?.stress_emotions ? healthHistory?.stress_emotions : '-'
});

export const getAdditionalInformation = (patient?: IPatientDetails) => ({
  // achieve: patient?.profile?.achieve_polln ? patient?.profile?.achieve_polln : '-',
  // share: patient?.profile?.addtl_info ? patient?.profile?.addtl_info : '-',
  // heard: patient?.profile?.about_polln ? patient?.profile?.about_polln : '-'
  mens_apperance: patient?.health_history?.mens_apperance
    ? (JSON.parse(patient?.health_history.mens_apperance) as string[])
    : ([] as string[]),
  mens_blood_color: patient?.health_history?.mens_blood_color
    ? (JSON.parse(patient?.health_history.mens_blood_color) as string[])
    : ([] as string[])
});

export const getPatientBackground = (background?: IPatientBackground) => ({
  approach_your_health: background?.approach_your_health
    ? (background.approach_your_health as string)
    : '',
  diagnosed_health_condition: background?.diagnosed_health_condition
    ? (JSON.parse(background?.diagnosed_health_condition) as string[])
    : ([] as string[]),
  diagnosed_health_condition_others: background?.diagnosed_health_condition_others
    ? (background.diagnosed_health_condition_others as string)
    : '',
  gastro: background?.gastro ? (JSON.parse(background?.gastro) as string[]) : ([] as string[]),
  health_goals: background?.health_goals
    ? (JSON.parse(background?.health_goals) as string[])
    : ([] as string[]),
  health_goals_others: background?.health_goals_others
    ? (background.health_goals_others as string)
    : '',
  hear_about: background?.hear_about ? (background.hear_about as string) : '',
  interested_speciality: background?.interested_speciality
    ? (background.interested_speciality as string)
    : '',
  menopausal: background?.menopausal
    ? (JSON.parse(background?.menopausal) as string[])
    : ([] as string[]),
  menstruation: background?.menstruation
    ? (JSON.parse(background?.menstruation) as string[])
    : ([] as string[]),
  mood: background?.mood ? (JSON.parse(background?.mood) as string[]) : ([] as string[]),
  other: background?.other ? (JSON.parse(background?.other) as string[]) : ([] as string[]),
  other_symptoms: background?.other_symptoms ? (background.other_symptoms as string) : '',
  pain: background?.pain ? (JSON.parse(background?.pain) as string[]) : ([] as string[]),
  sexual_health: background?.sexual_health
    ? (JSON.parse(background?.sexual_health) as string[])
    : ([] as string[]),
  undiagnosed_health_condition: background?.undiagnosed_health_condition
    ? (background.undiagnosed_health_condition as string)
    : '',
  vaginal_health: background?.vaginal_health
    ? (JSON.parse(background?.vaginal_health) as string[])
    : ([] as string[]),
  virtual_not_appropriate_reason: background?.virtual_not_appropriate_reason
    ? (background.virtual_not_appropriate_reason as string)
    : '',
  what_brings_you_to_hazel: background?.what_brings_you_to_hazel
    ? (background.what_brings_you_to_hazel as string)
    : '',
  whatelse_interested: background?.whatelse_interested
    ? (JSON.parse(background?.whatelse_interested) as string[])
    : ([] as string[]),
  whatelse_interested_others: background?.whatelse_interested_others
    ? (background.whatelse_interested_others as string)
    : '',
  experiencing_any_pain: background?.experiencing_any_pain
    ? (JSON.parse(background.experiencing_any_pain) as string[])
    : ([] as string[]),
  experiencing_any_pain_other: background?.experiencing_any_pain_other
    ? (background.experiencing_any_pain_other as string)
    : ''
});

export const getFrequencyMenstrual = (frequency: string) => {
  let patientFrequency: string = '';
  switch (frequency) {
    case '0':
      patientFrequency = 'Irregular cycle - shorter than 21 days, longer than 35 or inconsistent';
      break;
    case '1':
      patientFrequency = 'Regular cycle - every 22- 34 days';
      break;
    case '2':
      patientFrequency =
        'Amenorrhea -absence of more than 3 menstrual cycles after having regular periods';
      break;
    case '3':
      patientFrequency = 'Determined by contraception- my cycle is influenced by my contraception';
      break;
    case '4':
      patientFrequency = "I've never had a period";
      break;
    case '5':
      patientFrequency = 'I am no longer having periods';
      break;
    case '6':
      patientFrequency = "I'm not sure";
      break;
    default:
      break;
  }
  return patientFrequency;
};

export const getPeriodLast = (period: string) => {
  let periodLast: string = '';
  switch (period) {
    case '0':
      periodLast = '1-3 days';
      break;
    case '1':
      periodLast = '3-7 days';
      break;
    case '2':
      periodLast = 'More than 7 days';
      break;
    case '3':
      periodLast = "I'm not sure";
      break;
    default:
      break;
  }
  return periodLast;
};

export const getMentrualFlow = (flow: string) => {
  let mentrualFlow: string = '';
  switch (flow) {
    case '0':
      mentrualFlow = 'Heavy flow - changing sanitary products every 1 -2 hours';
      break;
    case '1':
      mentrualFlow = 'Moderate flow - changing sanitary products every 3 hours';
      break;
    case '2':
      mentrualFlow = 'Light flow - changing sanitary products every 4 - 5+ hours';
      break;
    case '3':
      mentrualFlow = 'Spotting - requiring only a sanitary liner or less to manage';
      break;
    case '4':
      mentrualFlow = 'Varies - combination of the above or varies across cycles';
      break;
    case '5':
      mentrualFlow = "I'm not sure";
      break;
    default:
      break;
  }
  return mentrualFlow;
};

export const getMenstrualPain = (pain: string) => {
  let painLevel: string = '';
  switch (pain) {
    case '0':
      painLevel = '0 No pain';
      break;
    case '1':
      painLevel = '1-3 Mild pain: noticeable but does not interfere with daily activities';
      break;
    case '2':
      painLevel = '4-6 Moderate pain: more noticeable and may interfere with daily activities';
      break;
    case '3':
      painLevel =
        '7-9 Severe pain: intense and limits daily activities, making it difficult to concentrate or sleep';
      break;
    case '4':
      painLevel = '10 Worst pain imaginable: unbearable and may cause nausea or vomiting';
      break;
    case '5':
      painLevel = "I'm not sure";
      break;
    default:
      break;
  }
  return painLevel;
};

export const getProfessionals = (professionals: string) => {
  let professionalsAns: string = '';
  switch (professionals) {
    case '0':
      professionalsAns = "I'm currently seeing healthcare professionals";
      break;
    case '1':
      professionalsAns =
        "I'm not currently seeing healthcare professionals, but I have in the past";
      break;
    case '2':
      professionalsAns =
        "I'm managing myself and have never seen healthcare professionals for these conditions/symptoms";
      break;
    default:
      break;
  }
  return professionalsAns;
};

export const getBooleanValue = (value: string) => {
  const answer: string = value === '1' ? 'Yes' : 'No';

  return answer;
};

export const getNextSchedules = (createSchedule: ICreateSchedule): [IDoctorDateSchedule?] => {
  let schedules: [IDoctorDateSchedule?] = [];
  for (let day of createSchedule.days) {
    let schedule = null;
    switch (day) {
      case 'MON':
        schedule = {
          start_at: nextMonday(createSchedule.start_at),
          end_at: nextMonday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'TUE':
        schedule = {
          start_at: nextTuesday(createSchedule.start_at),
          end_at: nextTuesday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'WED':
        schedule = {
          start_at: nextWednesday(createSchedule.start_at),
          end_at: nextWednesday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'THU':
        schedule = {
          start_at: nextThursday(createSchedule.start_at),
          end_at: nextThursday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'FRI':
        schedule = {
          start_at: nextFriday(createSchedule.start_at),
          end_at: nextFriday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'SAT':
        schedule = {
          start_at: nextSaturday(createSchedule.start_at),
          end_at: nextSaturday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'SUN':
        schedule = {
          start_at: nextSunday(createSchedule.start_at),
          end_at: nextSunday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      default:
        break;
    }
    if (schedule) {
      schedules.push(schedule);
    }
  }
  return schedules;
};

export const lifestyleLabel = (value: string): string => {
  const exists = LIFESTYLE_ADVICE.find((advice) => advice.value === value);
  if (exists) {
    return exists.label;
  } else {
    return '-';
  }
};

export const referalsTypeLabel = (value: string): string => {
  const exists = REFERRAL_TYPE.find((advice) => advice.value === value);
  if (exists) {
    return exists.label;
  } else {
    return '-';
  }
};

export const referalsTimeframeLabel = (value: string): string => {
  const exists = REFERRAL_TIMEFRAME.find((advice) => advice.value === value);
  if (exists) {
    return exists.label;
  } else {
    return '-';
  }
};


export const checkDate = (inputDate: Date) => {
  const today = startOfDay(new Date());
  const isoDate = typeof inputDate === 'string' ? inputDate : inputDate.toISOString();
  const compareDate = startOfDay(parseISO(isoDate));

  return isBefore(compareDate, today) ? false : true;
};