import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { IMenuTypes } from 'utils/models';

// components
import SideActions from './SideActions';
import Modal from './Modal';
import ModalProductInfo from './modals/ModalProductInfo';
import { SvgSideDashboard, SvgSidePatients, SvgSideShedule } from './SvgIcons';
import storage from 'utils/storage';

const SideBar: FC = () => {
  const [isCollapse, setIsCollapse] = useState<boolean>(true);
  const [openProductInfo, setOpenProductInfo] = useState<boolean>(false);

  const links: IMenuTypes[] = [
    {
      icon: <SvgSideDashboard className="w-5 h-5" />,
      label: 'Overview',
      to: '/dashboard'
    },
    {
      icon: <SvgSideShedule className="w-5 h-5" />,
      label: 'Schedule',
      to: '/schedule'
    },
    {
      icon: <SvgSidePatients className="w-5 h-5" />,
      label: 'Patients',
      to: '/patient-list'
    }
  ];

  useEffect(() => {
    const collapse = storage.getItem('collapse');
    if (collapse) setIsCollapse(JSON.parse(collapse));
  }, []);

  return (
    <>
      <div className="hidden md:block h-[calc(100dvh-7rem)] mt-6">
        <div className={clsx('bg-white h-full relative rounded-r-md', !isCollapse && 'w-50')}>
          <div className="pt-5">
            <SideActions actions={links} isCollapse={true} />
          </div>
        </div>
      </div>

      <Modal
        open={openProductInfo}
        onClose={setOpenProductInfo}
        size="7xl"
        classNameChild="!p-0"
        round="lg"
      >
        <ModalProductInfo showRecentView showSavedProducts />
      </Modal>
    </>
  );
};

export default SideBar;
